<template>
  <div class="recover-password-view pt-4">
    <div class="recover-password-header">
      <img
        width="300"
        src="@/assets/rectoplus_logo_white_font.png"
      >
    </div>
    <div class="form-recover-password-container">
      <span class="recover-password-title">{{ $t('views.recover_password.title') }}</span>
      <span class="recover-password-info">{{ $t('views.recover_password.info') }}</span>

      <v-form
        class="recover-password-form"
        @keyup.native.enter="recoveringPassword"
      >
        <ValidationObserver
          v-slot="{ invalid }"
          class="w-100"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="email"
            class="w-100"
            rules="required|email"
          >
            <v-text-field
              id="emailInput"
              v-model="email"
              class="text-field"
              :label="$t('components.login_form.placeholders.email')"
              type="email"
              background-color="field_register"
              flat
              solo
              :error-messages="errors"
            />
          </ValidationProvider>

          <RectoplusButton
            :disabled="invalid"
            class="submit-button"
            :text="$t('views.recover_password.button')"
            @click="recoveringPassword"
          />
        </ValidationObserver>
      </v-form>
      <router-link
        :to="{ name: 'login' }"
      >
        {{ $t('views.recover_password.back_to_login') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'RecoverPassword',
  components: {
    RectoplusButton,
  },
  data() {
    return {
      email: '',
    };
  },
  methods: {
    ...mapActions([
      'initLoading',
    ]),
    recoveringPassword() {
      if (this.hasSession) return this.$router.push('/home');
      this.initLoading();
      this.$store.dispatch('recoverPassword', { email: this.email });

      this.email = '';
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.recover-password-view {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .recover-password-header {
    color: $title_color_primary;
    font-size: 40px;
    font-weight: bold;
  }

  .recover-password-title {
    color: $title_color_primary;
    font-size: 26px;
    font-weight: bold;
  }

  .recover-password-info {
    color: $subtitle_color_primary;
    font-size: 16px;
  }

  .form-recover-password-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    text-align: center;

    .recover-password-form {
      margin: 32px 16px 16px;
      max-width: 400px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .text-field {
        width: 100%;
        max-width: 500px;
        border-radius: 8px;
      }

      .submit-button {
        width: 100%;
        max-width: 416px;
        margin: 20px 0px;
        font-size: 16px;
        height: 40px;
      }
    }
  }
}
</style>
